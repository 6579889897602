import './App.css';

function App() {
  const displayEmail = () => {
    let emailE = 'b-average.com';
    emailE = 'mailto:matt@' + emailE;
    return emailE;
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>B Average Inc.</h1>
        <a href={displayEmail()}>Contact</a>
      </header>
    </div>
  );
}

export default App;
